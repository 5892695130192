'use strict'
var __createBinding =
  (this && this.__createBinding) ||
  (Object.create
    ? function (o, m, k, k2) {
        if (k2 === undefined) k2 = k
        Object.defineProperty(o, k2, {
          enumerable: true,
          get: function () {
            return m[k]
          },
        })
      }
    : function (o, m, k, k2) {
        if (k2 === undefined) k2 = k
        o[k2] = m[k]
      })
var __setModuleDefault =
  (this && this.__setModuleDefault) ||
  (Object.create
    ? function (o, v) {
        Object.defineProperty(o, 'default', { enumerable: true, value: v })
      }
    : function (o, v) {
        o['default'] = v
      })
var __importStar =
  (this && this.__importStar) ||
  function (mod) {
    if (mod && mod.__esModule) return mod
    var result = {}
    if (mod != null)
      for (var k in mod)
        if (k !== 'default' && Object.prototype.hasOwnProperty.call(mod, k))
          __createBinding(result, mod, k)
    __setModuleDefault(result, mod)
    return result
  }
Object.defineProperty(exports, '__esModule', { value: true })
exports.WalletWidget = void 0
const jsx_runtime_1 = require('react/jsx-runtime')
const core_1 = require('@telekomconsalting/core')
const react_1 = __importStar(require('react'))
const WalletButton = react_1.default.lazy(() =>
  Promise.resolve().then(() => __importStar(require('./WalletButton')))
)
const WalletAccountButton = react_1.default.lazy(() =>
  Promise.resolve().then(() => __importStar(require('./WalletAccountButton')))
)
const WalletWidget = (props) => {
  const { account } = (0, core_1.useWeb3React)()
  if (!account) {
    return (0, jsx_runtime_1.jsx)(
      react_1.Suspense,
      Object.assign(
        { fallback: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}, void 0) },
        { children: (0, jsx_runtime_1.jsx)(WalletButton, Object.assign({}, props), void 0) }
      ),
      void 0
    )
  }
  return (0, jsx_runtime_1.jsx)(
    react_1.Suspense,
    Object.assign(
      { fallback: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}, void 0) },
      { children: (0, jsx_runtime_1.jsx)(WalletAccountButton, Object.assign({}, props), void 0) }
    ),
    void 0
  )
}
exports.WalletWidget = WalletWidget
exports.default = exports.WalletWidget
